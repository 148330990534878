import * as React from "react";
import Layout from "../components/Layout";
import HomeHero from "../images/Hyloq/HomeSlider_BG.jpg";
import HeroMap from "../images/Hyloq/Hero-Map.png";
import MarkerRadiate from "../images/Hyloq/Marker-Radiate.svg";
import HomeGroupMap from "../images/Hyloq/Home-Group-Map.png";
import { Helmet } from "react-helmet";

const hyloq = () => {
  return (
    <Layout>
      <Helmet>
        <title>Hyloq | True-scope Geofence Advertising</title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://imgix.cosmicjs.com/e49a2c70-14c7-11ed-b0e6-bf31569d7c90-ICON-TRANS-03.png"
        ></link>
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://hyloq.com/"
        ></link>
        <meta data-react-helmet="true" charset="utf-8"></meta>
        <meta
          data-react-helmet="true"
          name="description"
          content="Hyloq is the first true-scope geofencing advertising platform, Geofenced ads matched with unparalled data feedback for effectkive, hyperlocal marketing."
        ></meta>
        <meta
          data-react-helmet="true"
          name="robots"
          contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:locale"
          content="en-US"
        ></meta>
        <meta data-react-helmet="true" property="og:type" content="page"></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Hyloq | True-Scope Geofencing Advertising Platform"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Hyloq is the first true-scope geofencing advertising platform. Geofenced ads matched with unparalled data feedback for effective, hyperlocal marketing."
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://hyloq.com/"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Hyloq | True-Scope Geofencing Advertising Platform"
        ></meta>
        <meta
          data-react-helmet="true"
          property="article:tag"
          content="geofencing , geofencing advertising , true-scrope"
        ></meta>
        <meta
          data-react-helmet="true"
          property="face-domain-verification"
          content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
        ></meta>
        <link
          href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
          async
        ></script>
      </Helmet>
      <div class="hero relative bg-black overflow-hidden">
        <div class="max-w-7xl mx-auto pt-5 md:pt-0 pb-10 md:pb-0">
          <div class="absolute inset-0">
            <img
              class="h-full w-full object-cover"
              src={HomeHero}
              alt="Hyloq Home"
            />
          </div>
          <div class="text-center relative z-10 pb-8 sm:pb-16 md:pb-36 lg:max-w-2xl lg:w-full ">
            <main class="mt-10 mx-auto max-w-7xl sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
              <div class="sm:text-center lg:text-left">
                <h1 class="uppercase text-4xl tracking-normal font-bold text-white sm:text-5xl md:text-7xl">
                  Hyper Simplicity
                </h1>
                <p class="uppercase mt-1 text-base font-light text-white sm:text-lg sm:max-w-xl sm:mx-auto md:text-5xl lg:mx-0">
                  Stand out in the noise
                </p>
                <p class="px-5 md:px-0 text-center md:text-left mt-3 md:pr-10 text-base text-white sm:mt-5 sm:text-lg max-w-xl mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Advanced geofencing software optimized for hyperlocal
                  marketing. Hyloq is the perfect marriage of data and digital.
                </p>

                <div class="mt-14">
                  <a
                    href="/contact"
                    class="inline-flex items-center text-blue-900 bg-blue-100 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:bg-blue-900 focus:outline-none hover:text-white"
                  >
                    Contact Sales
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div class="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5">
          <img
            class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={HeroMap}
            alt="Geofencing Map"
          />
        </div>
      </div>
      <div class="bg-white py-20 about">
        <div class="mx-auto max-w-7xl">
          <div class="px-5 md:px-0 flex justify-between items-center">
            <div class="md:w-2/3">
              <h2 class="text-3xl md:text-5xl uppercase font-extrabold text-gray-700 tracking-tight">
                About Hyloq
              </h2>
              <p class="mt-4 max-w-4xl mx-auto text-lg text-gray-600">
                Short for hyper-location, Hyloq [pronounced hi-lock] was founded
                in 2020 with the intention of simplifying the geofence
                advertising process and giving control back to marketers.
                Hyloq’s services continue to expand, and now include access to
                OTT, CTV, and Digital Radio & Podcasting exchanges, making it
                easy for marketers to buy digital media all in one place. The
                company was acquired by Consult FGC in 2022 and continues to
                serve customers unparalleled access and insight.
              </p>
            </div>
            <div class="hidden md:block md:w-1/3 ">
              <img
                class="pl-10 pt-8 mx-auto"
                src="https://imgix.cosmicjs.com/e5b63960-7374-11ef-9f27-0d70a946df1f-Hyloq_Logotype_RedandBlue.png"
                alt="Hyloq Logo"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div class="treasure relative bg-teal-50 py-20 overflow-hidden">
        <div class="px-5 md:px-0 relative">
          <div class="mt-6 mb-14 text-center">
            <h2 class="uppercase text-3xl md:text-5xl font-extrabold tracking-tight text-gray-700">
              Treasure Trove of Targeting
            </h2>
            <p class="mt-4 max-w-4xl mx-auto text-lg text-gray-500">
              Programmatic advertising is now hyper-simple. Easily set up
              campaigns that can target a range of digital media buys, including
              Display, OTT & CTV, Digital Audio, & more!
            </p>
          </div>
          <div class="lg:mx-auto lg:max-w-7xl flex justify-center items-center">
            <div class="w-full md:w-11/12">
              <div class="md:mr-10">
                <a
                  href="https://imgix.cosmicjs.com/35b72350-14c9-11ed-b0e6-bf31569d7c90-Treasure.png"
                  target="blank"
                  class="hover:opacity-80"
                >
                  <img
                    class="w-full rounded-xl shadow-xl"
                    src="https://imgix.cosmicjs.com/35b72350-14c9-11ed-b0e6-bf31569d7c90-Treasure.png"
                    alt="Hyloq Media Planner"
                  />
                </a>
              </div>
            </div>

            <div class="hidden md:block">
              <dl class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                <div class="relative">
                  <dt>
                    <svg
                      class="absolute h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <p class="ml-9 text-lg leading-6 font-medium text-blue-700">
                      OTT & CTV
                    </p>
                  </dt>
                </div>

                <div class="relative">
                  <dt>
                    <svg
                      class="absolute h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <p class="ml-9 text-lg leading-6 font-medium text-blue-700">
                      Display
                    </p>
                  </dt>
                </div>

                <div class="relative">
                  <dt>
                    <svg
                      class="absolute h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <p class="ml-9 text-lg leading-6 font-medium text-blue-700">
                      Digital Audio
                    </p>
                  </dt>
                </div>

                <div class="relative">
                  <dt>
                    <svg
                      class="absolute h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <p class="ml-9 text-lg leading-6 font-medium text-blue-700">
                      & More!
                    </p>
                  </dt>
                </div>
              </dl>
            </div>
          </div>
        </div>{" "}
      </div>
      <div class="bg-gray-50 get valuable data">
        <div class="relative overflow-hidden">
          <div class="max-w-7xl pb-20 mx-auto">
            <div class="px-5 md:px-0 relative  pb-8 sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full  ">
              <main class="mt-10 mx-auto max-w-7xl sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
                <div class="sm:text-center lg:text-left">
                  <p class="uppercase pt-10 text-3xl font-semibold text-gray-700 mx-auto md:mt-5 md:text-5xl lg:mx-0">
                    Hyloq + CityHub
                  </p>
                  <p class="mt-3 pr-10 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-3xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Get valuable information on users in your geofence. Find out
                    who your customer really is.
                  </p>
                  <div class="pt-8 sm:mt-8 sm:flex sm:justify-center lg:justify-start items-center">
                    <img
                      class="hidden md:block w-1/5 "
                      src={MarkerRadiate}
                      alt="Hyloq ID"
                    />
                    <div class="px-10 md:ml-10 bg-white shadow shadow-lg overflow-hidden rounded-2xl">
                      <ul class="divide-y divide-gray-200">
                        <li class="text-2xl text-red-600 uppercase font-extrabold py-4">
                          Hyloq Id: <span class="text-black">555-555-555</span>
                        </li>
                        <div class="flex justify-start">
                          <li class="inline py-4 text-gray-700">
                            Dwell:{" "}
                            <span class="text-gray-700 font-bold">
                              {" "}
                              30 mins
                            </span>{" "}
                          </li>
                          <li class="ml-5 py-4 text-gray-700">
                            Enter:{" "}
                            <span class="text-gray-700 font-bold">
                              {" "}
                              3:00 pm
                            </span>{" "}
                          </li>
                          <li class="ml-5 py-4 text-gray-700">
                            Leaves:{" "}
                            <span class="text-gray-700 font-bold">
                              {" "}
                              3:30 pm
                            </span>{" "}
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
          <div class="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/3">
            <img
              class="-mt-5 h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src={HomeGroupMap}
              alt="Geofencing Map"
            />
          </div>
        </div>

        <div class="px-5 md:px-0 relative -mt-6 pb-24  max-w-7xl mx-auto">
          <div class="flex">
            <div class=" text-center w-full md:w-1/4 rounded-b-xl shadow shadow-xl">
              <div class="pt-3 pb-3 rounded-t-xl uppercase font-bold bg-gradient-to-l from-cyan-400 to-blue-500 text-white ">
                gender
              </div>
              <p class="pt-7 pb-7 bg-white rounded-b-xl">Female</p>
            </div>
            <div class="ml-7  text-center w-full md:w-1/4 rounded-b-xl shadow shadow-xl">
              <div class="pt-3 pb-3 rounded-t-xl uppercase font-bold bg-gradient-to-l from-cyan-400 to-blue-500 text-white ">
                Credit score
              </div>
              <p class="pt-7 pb-7 bg-white rounded-b-xl">650 - 705</p>
            </div>
            <div class="ml-7  text-center w-full md:w-1/4 rounded-b-xl shadow shadow-xl">
              <div class="pt-3 pb-3 rounded-t-xl uppercase font-bold bg-gradient-to-l from-cyan-400 to-blue-500 text-white ">
                children
              </div>
              <p class="pt-7 pb-7 bg-white rounded-b-xl">Y - Age 5 - 9</p>
            </div>
          </div>
        </div>
      </div>{" "}
      <div class="reporting bg-cyan-50">
        <div class="px-5 md:px-0 pt-28 pb-28 max-w-7xl mx-auto">
          <h1 class="uppercase text-center text-3xl tracking-normal font-bold text-gray-700 sm:text-5xl">
            Unparalleled Data Options
          </h1>
          <p class="text-center mt-3 md:pr-10 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-5xl sm:mx-auto md:mt-5 md:text-xl">
            When it comes to your data, Hyloq gives you a range of options to
            meet your needs. Generate first-party data with tracking and
            conversion pixels or access our large inventory of third-party
            audience segments. Expand your targeting any way you like.
          </p>
          <div class=" pt-16 flex justify-center">
            <div class="">
              <a
                class="hover:opacity-60"
                href="https://imgix.cosmicjs.com/788c0390-14c8-11ed-b0e6-bf31569d7c90-Unparallel.png"
                target="blank"
              >
                <img
                  class="shadow shadow-xl w-full rounded-2xl"
                  src="https://imgix.cosmicjs.com/788c0390-14c8-11ed-b0e6-bf31569d7c90-Unparallel.png"
                  alt="Hyloq 3rd Party Audience"
                />
              </a>
            </div>
          </div>
          <div class="text-center mt-14">
            <a
              href="/contact"
              class="inline-flex text-white bg-blue-800 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:bg-blue-900 focus:outline-none hover:text-white"
            >
              Contact Sales
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default hyloq;
